input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='text']:disabled,
input[readonly]:not(.datepicker) {
  background: $inputDisabled !important;
  cursor: no-drop;
  -webkit-text-fill-color: $inputcolor !important; // Safari
  -webkit-opacity: 1 !important;
  color: $inputcolor;
}

input[type='password'] {
  text-transform: none !important;
}

input[type='file'] {
  cursor: pointer !important;
}

.form-control:focus {
  box-shadow: none !important;
}

input,
textarea,
select {
  border-bottom: 0;
  transition: all 0.1s ease !important;
  &:focus {
    border-bottom: 1px solid #ddd9d9 !important;
  }
}

.input-group {
  box-shadow: 0px 2px 4px 1px rgb(0 0 0 / 30%);
  @include borderR();
}

// z-indez reduzido
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 0 !important;
}

textarea {
  @include input-primary;
  height: auto !important;
}

// vee-validationicon valid removed
.was-validated .form-control:valid,
.form-control.is-valid {
  padding-left: 8px;
  padding-right: 0;
  background-image: none;
}

.dp__input_wrap {
  .dp__input_icon {
    display: none !important;
  }
}

input {
  @include input-primary;
  box-shadow: none !important; //rgba(149, 157, 165, 0.2) 0px 2px 4px !important;
  border-radius: 0.5rem !important;
  &.hasUnit {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  &.dp__input {
    @include input-primary;
    &:hover,
    &:active,
    &.active {
      border: none !important;
    }
  }
}

.form-control {
  @include input-primary;
  color: $inputcolor !important;
  &--select2 {
    padding: 0.3rem 0rem !important;
    position: relative;
    z-index: 41 !important;
  }
}

//SELECT
.select-control-filter {
  padding: 0.3rem 0.75rem;
}
