:root {
  --corPrincipal: #006ec8;
  --textosPrincipais: #ffc418;
  --textosSecundarios: #ffffff;
}

$baseColor: #f9fafb;
$corPrincipal: var(--corPrincipal);
$textosPrincipais: var(--textosPrincipais);
$textosSecundarios: var(--textosSecundarios);
$TextorColorDefault: rgba(33, 37, 41, 0.75);

$error: #dc3545; // bg msg erro
$white: #ffffff;
$black: #000000;

//-> barra de scroll
$corScrollbar: $corPrincipal;
$corScrollbarBack: #e0e0e0;

//-> bg's
$bodyBg: #f9fafb;
$bodyColor: black;
$bgNav: #e3f2fd;

//-> botoes
$bgBtn: $corPrincipal; //linear-gradient(0deg, rgba(222,168,4,1) 0%, rgba(245,203,52,1) 63%);
$btColor: $white;
$bgBtnHover: $corPrincipal; //linear-gradient(0deg, rgb(73, 73, 64) 0%, rgb(31, 31, 31) 63%);
$btColorHover: $white;
$boxShadowBtn: 0px 8px 16px 0px rgba(0, 0, 0, 0.4);

//-> modais
$bgModal: $baseColor;
$modalClose: $white;
$corTitleModal: $white;

//-> textos
$textColorPrimary: $textosPrincipais;
$textColorSecondary: $textosSecundarios;

//-> layout
$maxWidth: 1142px;
$menuHeight: 64px;
$bannerHeight: 282px;
$borderRadius: 0.5rem;
$borderColor: #ddd9d9;
$padding: 40px;
$paddingInternas: 120px;
$paddingMobileInternas: 80px;

//-> inputs
$inputHeight: 40px;
$inputBg: $white;
$inputcolor: #444;
$inputDisabled: #c5c5c5;
$fontInput: 1rem; //22px;

//-> fonts
$font: 'arial';
$fontB: 'arial';
$fontI: 'arial';
$fontSize: 1rem; //21px
$fontText: 'arial';
$fontMenu: 1.0625rem; //17px
$fontTitle: 2.25rem; //36

//-> media querie
$screenXL: 1250px;
$screenLG: 992px;
$screenMD: 850px;
$mobile: 768px;
$screenSD: 576px;
